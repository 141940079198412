import { render, staticRenderFns } from "./Clubs.vue?vue&type=template&id=d84e0582&xmlns=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml&"
import script from "./Clubs.vue?vue&type=script&lang=js&"
export * from "./Clubs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports