//TODO: COnsolidate with clubsConfig.
// Address can live on the cosmic side.
export default Object.freeze({
  'bestiario-tulum': {
    name: 'Bestiario',
    price: 3,
    directions: {
      address:
        'Calle Sol Ote. 75, entre Orión Sur y Centauro Sur, Tulum Centro, 77760 Tulum, Q.R., México',
      googleMap:
        'pb=!1m18!1m12!1m3!1d3744.1576678418355!2d-87.46206662659434!3d20.210768215374024!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4fd12059f12927%3A0x85603b89b4812e30!2sBestiario%20Tulum!5e0!3m2!1ses!2ses!4v1726574747868!5m2!1ses!2ses',
    },
    tags: [
      {label: 'DAY', value: '-- -- ', desc: 'Closed During The Day.'},
      {
        label: 'NIGHT',
        value: '10PM-TIL LATE',
        desc:
          'Most lighted in the dark hours of the night. From 10PM until late. Closes on Tuesday.',
      },
      {
        label: 'SHOW',
        value: 'DJ SET',
        desc: 'Nightclub vibes, with a dance floor.',
      },
      {
        label: 'MUSIC',
        value: 'TECH/HOUSE',
        desc:
          'Tech & Latin House are part of this nightclubs signature. Independent DJs and Producers',
      },
      {
        label: 'DRESS',
        value: 'ELEGAN/CASUAL',
        desc:
          'Social/Nightclub vibes, relaxed but glamorous.',
      },
      {
        label: 'GOOD FOR',
        value: 'LATE PARTY',
        desc:
          'From Wednesdays to Sunday, during night hours Bestiario offers a consistent option for a late party.',
      },
    ],
  },
  'bonbonniere-tulum': {
    name: 'Bonbonniere',
    price: 8,
    directions: {
      address:
        'Carretera estatal Tulum - Boca Paila, Parcela 1744-A NCPE José María Pino Suárez, Tulum Beach, 77760 Tulum, Q.R., México',
      googleMap:
        'pb=!1m18!1m12!1m3!1d3745.5371025285335!2d-87.46260261093984!3d20.153350469028418!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4fd10a72ecdb47%3A0x97cf18089c83ca0a!2sBonbonniere%20Tulum!5e0!3m2!1ses-419!2smx!4v1696058437567!5m2!1ses-419!2smx',
    },
    tags: [
      {label: 'DAY', value: '-- -- ', desc: 'Closed During The Day.'},
      {
        label: 'NIGHT',
        value: '10PM-TIL LATE',
        desc:
          'Filled with energy and life during the dark hours of the night. From 10PM until late hours. From Wednesday to Sunday. ',
      },
      {
        label: 'SHOW',
        value: 'DJ SET',
        desc: 'Nightclub vibes, with a central dance floor.',
      },
      {
        label: 'MUSIC',
        value: 'TECH/HOUSE',
        desc:
          'Tech & Latin House are the ingredients on most Bonbonniere Nights. DJ Repertoire: Claptone, Melé, Lee Burridge, Nick Warren, Francisco Allendes, Sam Paganini, Damian Lazarus, Michael Bibi, Chus, Amémé',
      },
      {
        label: 'DRESS',
        value: 'ELEGANT',
        desc:
          'Elegant Formal | No sandals, flip flops, bathing suits, or sports clothing.👗👠 \n Gentlemen must wear closed shoes and must not wear sleeveless shirts.',
      },
      {
        label: 'GOOD FOR',
        value: 'LATE PARTY',
        desc:
          'From Wednesdays to Sunday, Bonbonniere is the place to go if you want to party until the late hours of the night.',
      },
    ],
  },
  'mia-beach-club-tulum': {
    name: 'Mia Tulum',
    price: 3,
    directions: {
      address:
        'Hotel Selina los Lirios, Carr. Tulum-Boca Paila KM 7.5-Interior, Tulum Beach, Punta Piedra, 77780 Tulum, Q.R., México',
      googleMap:
        'pb=!1m18!1m12!1m3!1d3745.498350753968!2d-87.45904312357546!3d20.154965617213666!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4fd1969ab7cd4f%3A0x1e16d0f07050acc5!2sMia%20Restaurant%20%26%20Beach%20Club!5e0!3m2!1ses-419!2smx!4v1696058697472!5m2!1ses-419!2smx',
    },
    tags: [
      {
        label: 'DAY',
        value: '11AM-7PM',
        desc: 'Offers experiences such as open air and beach parties.',
      },
      {
        label: 'NIGHT',
        value: '7PM-1AM',
        desc: 'Great atmosphere during the dark hours of the night. ',
      },
      {
        label: 'SHOW',
        value: 'DJ SET',
        desc: 'Beach parties with a sand dance floor.',
      },
      {
        label: 'MUSIC',
        value: 'TECH/HOUSE',
        desc:
          'A variety of electronic music styles. DJ Repertoire: Monolink, Oliver Koletski, Stavroz, Undercatt, Monkey Safari, Black Coffee, Dorian Craft, Mind Against, Nick Warren',
      },
      {label: 'DRESS', value: 'RELAXED', desc: 'No Dress Code!'},
      {
        label: 'GOOD FOR',
        value: 'PARTY',
        desc: 'Daily activities during the day & night',
      },
    ],
  },
  'bagatelle-tulum': {
    name: 'Bagatelle Tulum',
    price: 8,
    directions: {
      address: 'Carr. Tulum-Boca Paila 8, Tulum Beach, 77760 Q.R., México',
      googleMap:
        'pb=!1m18!1m12!1m3!1d3745.5379458812695!2d-87.45928152357551!3d20.153315317268085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4fd77047653e09%3A0x92da43faf7e30059!2sBagatelle%20Tulum!5e0!3m2!1ses-419!2smx!4v1696060014647!5m2!1ses-419!2smx',
    },
    tags: [
      {label: 'DAY', value: '11AM-7PM', desc: 'Beach Club & Restaurant'},
      {label: 'NIGHT', value: '7PM-1AM', desc: 'Restaurant + Show'},
      {
        label: 'SHOW',
        value: 'DINNER + PARTY',
        desc:
          'Entertainment such as dance or music shows. Live Shows, Dinner & DJ Sets.',
      },
      {label: 'MUSIC', value: 'DISCO/HOUSE', desc: 'DJs or Residents.'},
      {
        label: 'DRESS',
        value: 'ELEGANT',
        desc:
          'Night: Smart Elegant Attire (Athletic wear, bathing suits, and flip-flops are strictly prohibited)',
      },
      {
        label: 'GOOD FOR',
        value: 'DINE & CELEBRATE',
        desc:
          'The perfect spot for an enjoyable dinner with spectacular themed shows.',
      },
    ],
  },
  'parole-tulum': {
    name: 'Parole',
    price: 3,
    directions: {
      address:
        'Km 7.8, Carr. Tulum-Boca Paila, Tulum Beach, Zona Hotelera, 77780 Tulum, Q.R., México',
      googleMap:
        'pb=!1m18!1m12!1m3!1d3745.6244334984726!2d-87.46193922659644!3d20.149710117393045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4fd17c4b9a3613%3A0xe9c66572e79952b0!2sParole%20%7C%20Italian%20Restaurant%20in%20Tulum!5e0!3m2!1ses!2ses!4v1726573530620!5m2!1ses!2ses',
    },
    tags: [
      {label: 'DAY', value: '-- -- ', desc: 'Closed During The Day.'},
      {
        label: 'NIGHT',
        value: '6PM-TIL LATE',
        desc:
          'From the afternoon until the late hours. From Wednesday to Sunday. ',
      },
      {
        label: 'SHOW',
        value: 'DJ AND VIOLIN SHOW',
        desc: 'Signature violin show and resident DJs to create a relaxed ambient for Dinner and Party.',
      },
      {
        label: 'MUSIC',
        value: 'LATIN TECH/HOUSE',
        desc:
          'Latin House and Violin Show',
      },
      {
        label: 'DRESS',
        value: 'SMART-CASUAL',
        desc:
          'Elegant/Smart Casual',
      },
      {
        label: 'GOOD FOR',
        value: 'ITALIAN FINE DINING',
        desc:
          'From Wednesdays to Sunday, Parole is the place to go for a fine dining experience inspired in Italian Cuisine and a Party Show.',
      },
    ],
  },
  'mamazzita-tulum': {
    name: 'Mamazzita',
    price: 3,
    directions: {
      address:
        'Carretera Tulum-Boca Paila Zona Hotelera Km 7, 77780 Tulum, Q.R., Mexico',
      googleMap:
        'pb=!1m18!1m12!1m3!1d3745.432608735102!2d-87.45869202659611!3d20.157705417129012!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4fd1cc3d4454ff%3A0x5251d369d2a8f68f!2sMamazzita%20%7C%20Mexican%20Restaurant%20in%20Tulum!5e0!3m2!1ses!2ses!4v1726572909406!5m2!1ses!2ses',
    },
    tags: [
      {label: 'DAY', value: '-- -- ', desc: 'Closed During The Day.'},
      {
        label: 'NIGHT',
        value: '6PM-TIL LATE',
        desc:
          'From the afternoon until the late hours. From Wednesday to Sunday. ',
      },
      {
        label: 'SHOW',
        value: 'MAYAN DRUM RITUALS',
        desc: 'DJs Residents and the signature Mayan Drums Show.',
      },
      {
        label: 'MUSIC',
        value: 'LATIN HOUSE',
        desc:
          'Tech & Latin House are the part of Mamazzita\'s Nights.',
      },
      {
        label: 'DRESS',
        value: 'SMART-CASUAL',
        desc:
          'Elegant/Smart Casual',
      },
      {
        label: 'GOOD FOR',
        value: 'DINNER + SHOW',
        desc:
          'From Wednesdays to Sunday, Mamazzita offers a select Mezcal and Tequila Mixology and Mexican Soul Party Ambient.',
      },
    ],
  },
  'tora-tulum': {
    name: 'Tora',
    price: 3,
    directions: {
      address:
        'Carr. Tulum-Boca Paila 5-Km 7, Tulum Beach, Zona Hotelera, 77780 Tulum, Q.R., Mexico',
      googleMap:
        'pb=!1m18!1m12!1m3!1d7491.23316936994!2d-87.46158142659641!3d20.150037317382253!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4fd1f378989bef%3A0x2d536c203ccb4721!2sTora%20%7C%20Japanese%20Restaurant%20in%20Tulum!5e0!3m2!1ses!2ses!4v1726571893677!5m2!1ses!2ses',
    },
    tags: [
      {label: 'DAY', value: '-- -- ', desc: 'Closed During The Day.'},
      {
        label: 'NIGHT',
        value: '6PM-TIL LATE',
        desc:
          'From 6PM onwards the japanese culinary experience commences, until the late hours. Open Daily. ',
      },
      {
        label: 'SHOW',
        value: 'TRIBAL FIRE DANCE',
        desc: 'Luxury Dining and Fiery Performances.',
      },
      {
        label: 'MUSIC',
        value: 'PARTY/HOUSE',
        desc:
          'House & Latin are the ingredients on the complex experience of Tora',
      },
      {
        label: 'DRESS',
        value: 'SMART-CASUAL',
        desc:
          'Elegant/Smart Casual',
      },
      {
        label: 'GOOD FOR',
        value: 'PARTY AMBIENCE AND DINNER',
        desc:
          'Tora offers a Daily option for a smart cuisine and PARTY/SHOW music and ambience.',
      },
    ],
  },
  'vagalume-tulum': {
    name: 'Vagalume',
    price: 5,
    directions: {
      address:
        'Supermanzana QROO 15, Tulum Beach, 77776 Tulum, Quintana Roo, México',
      googleMap:
        'pb=!1m18!1m12!1m3!1d14982.299491968302!2d-87.46857647882655!3d20.151776101837473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4fd1b10ad98c51%3A0xf2255e0ff55990!2sVagalume%20Tulum!5e0!3m2!1ses-419!2smx!4v1695886579015!5m2!1ses-419!2smx',
    },
    tags: [
      {
        label: 'DAY',
        value: '11AM-6PM',
        desc:
          'Open during the day, and offer experiences such as open air and beach parties.',
      },
      {
        label: 'NIGHT',
        value: '7PM-1AM',
        desc:
          'Filled with energy and life during the dark hours of the night. ',
      },
      {
        label: 'SHOW',
        value: 'DJ SET',
        desc:
          'Beachside DJ Sets and themed events during the night at party tents.',
      },
      {
        label: 'MUSIC',
        value: 'TECH/HOUSE',
        desc:
          'House, Tech & Organic House. Highly influenced by Ibiza. DJ Repertoire: Anna, Tara Brooks, Brian Cid, Sebastien Leger, Yulia Niko, Gheist, Echonomist, Nick Warren, Mestiza, Francis Mercier',
      },
      {
        label: 'DRESS',
        value: 'BOHO CHIC',
        desc:
          'Boho-chic is a style of fashion drawing on various bohemian and hippie influences, which, at its height in late 2005 was associated particularly with actress Sienna Miller.',
      },
      {
        label: 'GOOD FOR',
        value: 'NIGHT PARTIES',
        desc: 'Vagalume is a hotspot for late-day/night parties.',
      },
    ],
  },
  "chambao-tulum": {
    name: 'Chambao',
    price: 3,
    directions: {
      address: 'Carretera Tulum-Boca Paila Zona Hotelera Km 7, 77780 Tulum, Q.R.',
      googleMap: 'pb=!1m14!1m8!1m3!1d14981.610650840392!2d-87.4554694!3d20.1589533!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4fd1e6ce008243%3A0x54cab4750b1f9cd8!2sChambao%20Tulum%20%7C%20Best%20Steakhouse%20in%20Tulum!5e0!3m2!1sen!2smx!4v1717093827096!5m2!1sen!2smx'
    },
    tags: [
      {label: 'DAY', value: '-', desc: 'Closed during the day.'},
      {label: 'NIGHT', value: '6PM-1AM', desc: 'Grill house restaurant for dinners & special shows.'},
      {
        label: 'SHOW',
        value: 'DINNER & SHOW',
        desc: 'Festive dinner sessions with live music, aerial & fire performances.'
      },
      {label: 'MUSIC', value: 'LATIN/DJ', desc: 'Residents DJs and live music.'},
      {label: 'DRESS', value: 'CHIC', desc: 'Casual-elegant (No sportswear/beachwear, and no flipflops).'},
      {label: 'GOOD FOR', value: 'DINNER & PARTY', desc: 'The perfect spot for Steak & Seafood specialties.'},
    ],
  },
  'confessions-tulum': {
    name: 'Confessions',
    price: 3,
    directions: {
      address:
        'Carretera Tulum-Boca Paila Zona Hotelera Km 7, Tulum Beach, 77780 Tulum, Q.R., México',
      googleMap:
        'pb=!1m18!1m12!1m3!1d3745.398848975644!2d-87.4578208235754!3d20.159112217076675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4fd1809891bd71%3A0x74d9556c4eb58fdf!2sConfessions%20%7C%20DJ%20Sessions%20%26%20Bar%20in%20Tulum!5e0!3m2!1ses-419!2smx!4v1696059278530!5m2!1ses-419!2smx',
    },
    tags: [
      {label: 'DAY', value: '-- --', desc: 'Closed during the day.'},
      {
        label: 'NIGHT',
        value: '8PM-1AM',
        desc: 'Opened from Thursday to Sunday.',
      },
      {
        label: 'SHOW',
        value: 'DJ SET',
        desc: 'A perfect spot for after-dinner parties.',
      },
      {
        label: 'MUSIC',
        value: 'TECH/HOUSE',
        desc:
          'International and Local DJ Sets. Mostly Latin House, DJ Repertoire: Eagles & Butterflies, Mr. Pig, Tom & Collins.',
      },
      {
        label: 'DRESS',
        value: 'CHIC',
        desc: 'Smart Casual (No sportswear/beachwear, and no flipflops).',
      },
      {label: 'GOOD FOR', value: 'LATE PARTY', desc: ''},
    ],
  },
  "funky-geisha-tulum": {
    name: 'Funky Geisha',
    price: 3,
    directions: {
      address: 'Km 7.8, Carr. Tulum-Boca Paila, Tulum Beach, Zona Hotelera, 77780 Tulum, Q.R.',
      googleMap: 'pb=!1m14!1m8!1m3!1d14982.517482183486!2d-87.4593531!3d20.1495043!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4fd163a954a85f%3A0xfa7ec3d3c13784c0!2sFunky%20Geisha%20%7C%20Asian%20Restaurant%20in%20Tulum!5e0!3m2!1sen!2smx'
    },
    tags: [
      {label: 'DAY', value: '-', desc: 'Closed during the day.'},
      {label: 'NIGHT', value: '6PM-1AM', desc: 'Asian Cuisine & special shows.'},
      {label: 'SHOW', value: 'DINNER & SHOW', desc: 'Taiko Drum show & dinner sessions with live music.'},
      {label: 'MUSIC', value: 'LIVE/LATIN', desc: 'Residents DJs and live music.'},
      {label: 'DRESS', value: 'CHIC', desc: 'Casual-elegant (No sportswear/beachwear, and no flipflops).'},
      {label: 'GOOD FOR', value: 'DINNER & SHOW', desc: 'Asian inspired dinner with a lively ambiance'},
    ],
  },
  'papaya-playa': {
    directions: {
      address:
        'Carr. Tulum-Boca Paila Km 4.5, Tulum Beach, Zona Costera, 77780 Tulum, Q.R., México',
      googleMap:
        'pb=!1m18!1m12!1m3!1d3744.8656914089834!2d-87.44766822357508!3d20.18131691634269!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4fd74b3025cfe9%3A0xe0c14ab003ca4613!2sPapaya%20Playa%20Project!5e0!3m2!1ses-419!2smx!4v1696058945024!5m2!1ses-419!2smx',
    },
    name: 'Papaya Playa',
    price: 8,
    tags: [
      {
        label: 'DAY',
        value: '10AM-8PM',
        desc: 'Offers experiences such as open air and beach parties.',
      },
      {
        label: 'NIGHT',
        value: '8PM-3AM',
        desc: 'Filled with energy and life during the dark hours of the night.',
      },
      {
        label: 'SHOW',
        value: 'DJ SET',
        desc: 'International and Local DJ performances.',
      },
      {
        label: 'MUSIC',
        value: 'TECH/HOUSE',
        desc:
          'Several genres of electronic music inhouse. DJ Repertoire: Rufus Du Sol, Woomon, Gliegling, Storytellers, WHOMADEWHO, Monolink, Lee Burridge, Bob Moses, Bedouin, Hvob.',
      },
      {
        label: 'DRESS',
        value: 'CHIC',
        desc: 'Dinner Casual (No sportswear/beachwear, and no flipflops).',
      },
      {
        label: 'GOOD FOR',
        value: 'SATURDAY NIGHT',
        desc:
          'Papaya Saturdays are a safe bet. Keep an eye on the full moon parties occurring once in a month.',
      },
    ],
  },
  'rosa-negra-tulum': {
    name: 'Rosa Negra',
    price: 7,
    directions: {
      address:
        'Carr. Tulum-Boca Paila KM 7.5, Tulum Beach, Zona Hotelera, 77780 Tulum, Q.R., México',
      googleMap:
        'pb=!1m18!1m12!1m3!1d3745.6185165813918!2d-87.46151432399603!3d20.149956781288473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4fd0cb6cfe72ab%3A0x4de3aba29e76c70c!2sRosaNegra%20%7C%20Latin%20American%20Restaurant%20in%20Tulum!5e0!3m2!1ses-419!2smx!4v1696060316225!5m2!1ses-419!2sm',
    },
    tags: [
      {label: 'DAY', value: '-- --', desc: 'Closed during the day.'},
      {
        label: 'NIGHT',
        value: '6PM-1AM',
        desc: 'The Perfect Restaurant for late dinner.',
      },
      {
        label: 'SHOW',
        value: 'DINNER & SHOW',
        desc: 'Festive dinner sessions with live music & performances',
      },
      {label: 'MUSIC', value: 'LIVE/LATIN', desc: 'DJs or Residents.'},
      {
        label: 'DRESS',
        value: 'CHIC',
        desc: 'Casual-elegant (No sportswear/beachwear, and no flipflops).',
      },
      {
        label: 'GOOD FOR',
        value: 'DINNER & PARTY',
        desc: 'The perfect spot for a dinner celebration.',
      },
    ],
  },
  'rosanegra-beach-club-tulum': {
    name: 'Rosanegra Beach Club in Tulum',
    price: 6,
    directions: {
      address:
        'Carr. Tulum-Boca Paila KM 7.5, Tulum Beach, Zona Hotelera, 77780 Tulum, Q.R., México',
      googleMap:
        'pb=!1m18!1m12!1m3!1d3745.6185165813918!2d-87.46151432399603!3d20.149956781288473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4fd0cb6cfe72ab%3A0x4de3aba29e76c70c!2sRosaNegra%20%7C%20Latin%20American%20Restaurant%20in%20Tulum!5e0!3m2!1ses-419!2smx!4v1696060316225!5m2!1ses-419!2sm',
    },
    photos: ['', '', '', ''],
    tags: [
      {
        label: 'DAY',
        value: '11:30AM-6:30PM',
        desc: 'Opened from Thursday to Sunday',
      },
      {
        label: 'NIGHT',
        value: '-- --',
        desc: 'Opened at night only for special events',
      },
      {label: 'SHOW', value: 'DJ SET', desc: 'Headliner Djs or Residents.'},
      {
        label: 'MUSIC',
        value: 'TECH/HOUSE',
        desc: 'Several genres of electronic music in house.',
      },
      {
        label: 'DRESS',
        value: 'RELAXED',
        desc:
          '👗👔 | Beach-themed attire for the day and Smart Casual for special night events.',
      },
      {label: 'GOOD FOR', value: 'SPECIAL EVENTS', desc: ''},
    ],
  },
  'gitano-tulum': {
    name: 'Gitano Jungle',
    price: 7,
    directions: {
      address: 'Beach Road Km. 7, Tulum Beach, 77710 Q.R., México',
      googleMap:
        'pb=!1m18!1m12!1m3!1d59926.410608613805!2d-87.48637075241005!3d20.159036625821788!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4fd0cb15944a15%3A0x1d87fa7c461e751f!2sGITANO!5e0!3m2!1ses-419!2smx!4v1696058301872!5m2!1ses-419!2smx',
    },
    tags: [
      {label: 'DAY', value: '-- --', desc: 'Closed during the day.'},
      {
        label: 'NIGHT',
        value: '6PM-1AM',
        desc: 'From Thursday to Sunday. Extended hours on Fridays.',
      },
      {
        label: 'SHOW',
        value: 'DJ SET',
        desc: 'Dinner, Live music, themed events, party-restaurant.',
      },
      {
        label: 'MUSIC',
        value: 'DISCO/HOUSE',
        desc:
          'Tropical rhythms, Disco, and House music. DJs Repertoire: Off Silence, The Versa, Le Tourist, Red Axes, Kid Siius, Fleets, Meqi, AAK, Savage & She, Ignacio. ',
      },
      {
        label: 'DRESS',
        value: 'CHIC',
        desc: 'Jungle Chic (No beachwear, and no flipflops).',
      },
      {
        label: 'GOOD FOR',
        value: 'LATE PARTY',
        desc: 'On Friday Nights it closes late.',
      },
    ],
  },
  'taboo-tulum': {
    name: 'Taboo Tulum',
    price: 8,
    directions: {
      address:
        'Carretera Tulum-Boca Paila Zona Hotelera Km 7, Tulum Beach, 77780 Tulum, Q.R., México',
      googleMap:
        'pb=!1m18!1m12!1m3!1d3745.402839886641!2d-87.4580462233988!3d20.158945917081805!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4fd7c6e0f9e411%3A0x9d2307e3cbafe374!2sTaboo%20%7C%20Best%20Beach%20Club%20Tulum!5e0!3m2!1ses-419!2smx!4v1695991208547!5m2!1ses-419!2smx',
    },
    tags: [
      {
        label: 'DAY',
        value: '11:30AM-6:30PM',
        desc: 'Beach Club, Happenings & Restaurant.',
      },
      {label: 'NIGHT', value: '6PM-1AM', desc: 'Restaurant + Happenings.'},
      {
        label: 'SHOW',
        value: 'LIVE MUSIC',
        desc: 'Sax & Violin Shows, Live music, and an energetic atmosphere',
      },
      {
        label: 'MUSIC',
        value: 'LATIN/LIVE',
        desc: 'DJs or residents raging latin inspired house and music',
      },
      {
        label: 'DRESS',
        value: 'RELAXED',
        desc:
          'Beach-themed attire for the day and Smart casual for the dinner time (7:00 pm).',
      },
      {
        label: 'GOOD FOR',
        value: 'DAY PARTY',
        desc: 'Taboo is the perfect choice to party during the day.',
      },
    ],
  },
  'tantra-tulum': {
    name: 'Tantra Tulum',
    price: 7,
    directions: {
      address:
        'Carr. Tulum-Boca Paila Km 7, Tulum Beach, Zona Hotelera, 77780 Tulum, Q.R., México',
      googleMap:
        'pb=!1m18!1m12!1m3!1d3745.4026631593038!2d-87.45804702411158!3d20.15895328128168!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4fd74b304039f5%3A0xed5aca5562990cbb!2sTantra%20%7C%20Best%20beach%20club%20in%20Tulum!5e0!3m2!1ses-419!2smx!4v1696060594872!5m2!1ses-419!2sm',
    },
    tags: [
      {
        label: 'DAY',
        value: '11:30AM-6:30PM',
        desc: 'Offers experiences such as open air and beach parties.',
      },
      {label: 'NIGHT', value: '-- --', desc: ''},
      {
        label: 'SHOW',
        value: 'LIVE MUSIC',
        desc: 'Shows with lively performances.',
      },
      {label: 'MUSIC', value: 'LATIN/LIVE', desc: 'DJs or Residents.'},
      {label: 'DRESS', value: 'RELAXED', desc: 'Beach-themed attire.'},
      {label: 'GOOD FOR', value: 'SHOW', desc: 'High Class Shows'},
    ],
  },
  'balabamba-tulum': {
    name: 'Balabamba Tulum',
    price: 7,
    directions: {
      address:
        'PREDIO PUNTA PIEDRA CARRETERA TULUM BOCA PAILA, Manzana 94, Tulum Beach, 77760 Tulum, Q.R.',
      googleMap:
        'pb=!1m14!1m8!1m3!1d14980.751112469452!2d-87.4512133!3d20.1679056!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4fd1820486f141%3A0x424dfd10c5a88a78!2sBalabamba%20Beach%20Club!5e0!3m2!1sen!2smx!4v1706923831105!5m2!1sen!2smx',
    },
    tags: [
      {
        label: 'DAY',
        value: '10AM-7PM',
        desc: 'Offers beach club access and day parties.',
      },
      {
        label: 'NIGHT',
        value: '7PM-12AM',
        desc: 'Special night events that combine rhythms, art, and music.',
      },
      {
        label: 'SHOW',
        value: 'DJ SET',
        desc: 'Beachside DJ Sets and themed events during the night.',
      },
      {
        label: 'MUSIC',
        value: 'TECH/HOUSE',
        desc:
          'House and Tech House. Local scene. DJ Repertoire: Valente, Rebolledo, The Funk District.',
      },
      {label: 'DRESS', value: 'RELAXED', desc: 'Beach Party.'},
      {
        label: 'GOOD FOR',
        value: 'PARTY',
        desc:
          'Great beachfront ambiance during the day and night, with live music.',
      },
    ],
  },
  'h-moonlight-tulum': {
    name: 'H MOONLIGHT',
    tags: [],
  },
  'ilios-restaurant-tulum': {
    name: 'ILIOS',
    directions: {
      address:
        'Carr. Tulum-Boca Paila Km. 8, Tulum Beach, Zona Hotelera, 77780 Tulum, Q.R., México',
      googleMap:
        'pb=!1m18!1m12!1m3!1d3745.540535448935!2d-87.46022638782138!3d20.153207381207878!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4fd172a4292d6f%3A0x7713c3b74b28a2aa!2sIlios%20%7C%20Greek%20restaurant%20in%20Tulum!5e0!3m2!1ses-419!2sus!4v1696307591327!5m2!1ses-419!2sus',
    },

    tags: [
      {label: 'DAY', value: '-- --', desc: 'Closed during the day.'},
      {label: 'NIGHT', value: '5PM-1AM', desc: 'Open daily.'},
      {
        label: 'SHOW',
        value: 'DINNER + SHOW',
        desc:
          'Greek dining experiences with fire shows, violin shows, live music, and plate-smashing.',
      },
      {label: 'MUSIC', value: 'LATIN', desc: 'DJs or Residents.'},
      {label: 'DRESS', value: 'CHIC', desc: 'Smart Casual Attire.'},
      {
        label: 'GOOD FOR',
        value: 'DINNER',
        desc: 'To celebrate special occasions or for a unique Greek dinner.',
      },
    ],
  },
  'kanan-beach-club-tulum': {
    name: 'Kanan Tulum Beach Club',
    price: 3,
    directions: {
      address:
        'Carretera Tulum Boca Paila Km 7.5 Mza 020 reg 009, 77780 Tulum, Q.R.',
      googleMap:
        'pb=!1m14!1m8!1m3!1d14982.405649845306!2d-87.4582348!3d20.1506698!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4fd1078851dd4f%3A0x869258734505ac8b!2sKanan%20Tulum!5e0!3m2!1sen!2smx!4v1707931735735!5m2!1sen!2smx',
    },

    tags: [
      {
        label: 'DAY',
        value: '11AM-5:30PM',
        desc: 'Enjoy the beach, food, and live music in a peaceful vibe.',
      },
      {
        label: 'NIGHT',
        value: '6PM-11PM',
        desc: 'Have Special Dinners & Restaurant Parties.',
      },
      {
        label: 'SHOW',
        value: 'DINNER & SHOW',
        desc:
          'Festive sessions with live music & performances. Full moon experiences.',
      },
      {
        label: 'MUSIC',
        value: 'TECH/HOUSE',
        desc:
          'House and Tech House. Local scene. DJs Repertoire: Nick Curly, Moiqe, Kleinfinger, Fedem, Java R.',
      },
      {
        label: 'DRESS',
        value: 'RELAXED',
        desc:
          'During the day beach attire & at night Tulum style (No sportswear/beachwear, and no flipflops).',
      },
      {
        label: 'GOOD FOR',
        value: 'UNIQUE SCENERY',
        desc:
          'Rustic elements, tree house atmosphere, and popular nests. Adults-only.',
      },
    ],
  },
  'gitano-beach-tulum': {
    name: 'Gitano Beach Tulum',
    price: 5,
    directions: {
      address:
        'Av Boca Paila KM 9.5, Tulum Beach, Zona Costera, 77780 Tulum, Q.R.',
      googleMap:
        'pb=!1m14!1m8!1m3!1d14984.19940982648!2d-87.4640416!3d20.1319677!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4fd1597d45794f%3A0xe94c71915bd04c86!2sGitano%20Beach!5e0!3m2!1sen!2smx!4v1705621705456!5m2!1sen!2smx',
    },
    tags: [
      {
        label: 'DAY',
        value: '11AM-7PM',
        desc: 'You will find live DJs, International cuisine & Mixology.',
      },
      {
        label: 'NIGHT',
        value: '7PM-11PM',
        desc:
          'International dining & some special night events that combine live music, beach & disco elements.',
      },
      {
        label: 'SHOW',
        value: 'DJ SET',
        desc:
          'Dinner, Beachside DJ sets, themed events, and Beach BBQ Fiesta on Sundays.',
      },
      {
        label: 'MUSIC',
        value: 'DISCO/HOUSE',
        desc:
          'Tropical rhythms, Disco, and House music. DJs Repertoire: Mestiza, Antonio Santana, Irena, Le Tourist, Rene.',
      },
      {
        label: 'DRESS',
        value: 'CHIC',
        desc:
          'During the day beachwear & at night Jungle Chic with No beachwear & no flip-flops.',
      },
      {
        label: 'GOOD FOR',
        value: 'PARTY',
        desc:
          'Great beachfront ambiance during the day and night, with live music.',
      },
    ],
  },
  'mayan-monkey-tulum': {
    name: 'Mayan Monkey',
    price: 1,
    directions: {
      address: 'Av. Coba Sur S/N, 77780 Tulum, Q.R.',
      googleMap:
        'pb=!1m14!1m8!1m3!1d14977.205618195409!2d-87.4502894!3d20.2047926!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4fd1565622e5ff%3A0xc41488f36ad99a0a!2sMayan%20Monkey%20Hostel%20Tulum!5e0!3m2!1sen!2smx!4v1705625453046!5m2!1sen!2smx',
    },

    tags: [
      {
        label: 'DAY',
        value: '11AM-7PM',
        desc: 'Hostel Facilities and a friendly atmosphere',
      },
      {
        label: 'NIGHT',
        value: '7PM-2AM',
        desc:
          'Lively DJ sets, special DJ guests, VIP spaces, and an inviting dancefloor',
      },
      {
        label: 'SHOW',
        value: 'DJ SET',
        desc: 'Special International DJs and Local DJ performances.',
      },
      {
        label: 'MUSIC',
        value: 'TECH/HOUSE',
        desc:
          'Several genres of electronic music. DJs Repertoire: Pablo Fierro, Gordo, Echonomist, Polo & Pan, Giorgio Stefano, Gabri.',
      },
      {label: 'DRESS', value: 'RELAXED', desc: 'Relaxed Tropical attire.'},
      {
        label: 'GOOD FOR',
        value: 'PARTY',
        desc: 'A great place to socialize, meet people, and enjoy DJ sets',
      },
    ],
  },
  'buuts-ha-tulum': {
    name: 'Buuts Ha',
    price: 1,
    directions: {
      address: '7JM3+XF, 77772 Balam Canché, Q.R.',
      googleMap:
        'pb=!1m18!1m12!1m3!1d3742.358718467651!2d-87.39570109999998!3d20.28541390000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4fd3fd9c8b4439%3A0x365ff512f4a79139!2sBuuts&#39;%20Ha&#39;%20Cenote%20Club!5e0!3m2!1sen!2smx!4v1706903082009!5m2!1sen!2smx',
    },

    tags: [
      {
        label: 'DAY',
        value: '10AM-6PM',
        desc: 'Relax at the Cenote Club & enjoy food, drinks, and live music',
      },
      {
        label: 'NIGHT',
        value: '6PM-11PM',
        desc: 'Lively DJ sets & special DJ guests',
      },
      {
        label: 'SHOW',
        value: 'DJ SET',
        desc:
          'Themed parties, DJ sets, and an open space to enjoy the cenote and Jungle',
      },
      {
        label: 'MUSIC',
        value: 'TECH/HOUSE',
        desc:
          'Several genres of electronic music. DJs Repertoire: Corcuera, Tiago Dima, Leonardo Wolf, Colossio, Lea Alva.',
      },
      {
        label: 'DRESS',
        value: 'RELAXED',
        desc: 'Swimwear, Boho, Tuluminati, Tropical',
      },
      {
        label: 'GOOD FOR',
        value: 'PARTY',
        desc: 'Good cenote club during the day and night, with live music.',
      },
    ],
  },
  'meze-tulum': {
    name: 'MEZE',
    price: 3,
    directions: {
      address: 'Carretera Tulum-Boca Paila Km 6.5 S/N, 77780 Tulum, Q.R.',
      googleMap:
        'pb=!1m18!1m12!1m3!1d3745.589208833041!2d-87.46128762542283!3d20.15117851734229!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4fd72c9e579dc9%3A0x11afd290be88c40c!2sMEZE%20Tulum!5e0!3m2!1sen!2smx!4v1706118522178!5m2!1sen!2smx',
    },

    tags: [
      {label: 'DAY', value: '', desc: 'Closed during the day.'},
      {
        label: 'NIGHT',
        value: '6PM-12AM',
        desc:
          'Mediterranean Greek dining experience & some special night events that combine live music, the jungle & disco elements.',
      },
      {
        label: 'SHOW',
        value: 'DINNER+SHOW',
        desc:
          'Greek dining experiences with fire shows, special cocktails, live music, and dancing.',
      },
      {
        label: 'MUSIC',
        value: 'DISCO/HOUSE',
        desc:
          'Tropical rhythms, Disco, and House music. DJs Repertoire: Claptone, Lee Jo Life, Maxi Meraki, The Versa, Giorgio Stefano.',
      },
      {
        label: 'DRESS',
        value: 'CHIC',
        desc: 'Jungle Chic (No beachwear, and no flipflops).',
      },
      {
        label: 'GOOD FOR',
        value: 'DINNER',
        desc:
          'Its dining room and Greek food are ideal for groups or to celebrate any special occasion.',
      },
    ],
  },
  "it-tulum": {
    name: 'IT TULUM',
    price: 2,
    directions: {
      address: 'Carretera Tulum-Boca Paila KM 6.2, Tulum Beach, Zona Hotelera, 77760 Tulum, Q.R.',
      googleMap: 'pb=!1m18!1m12!1m3!1d3745.33513932506!2d-87.45454670000001!3d20.161766799999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4fd70bcaf2673b%3A0x180b00558098f9d5!2sIT%20Tulum!5e0!3m2!1sen!2smx!4v1734477408154!5m2!1sen!2smx'
    },
    tags: [
      {label: 'DAY', value: '-', desc: 'Closed during the day.'},
      {label: 'NIGHT', value: '3PM-11PM', desc: 'Open air formats.'},
      {label: 'SHOW', value: 'DJ SET', desc: 'The perfect spot for DJ sets by the beach.'},
      {label: 'MUSIC', value: 'TECH/HOUSE', desc: 'International and Local DJ Sets.'},
      {label: 'DRESS', value: 'CHIC', desc: 'Smart Casual (No sportswear/beachwear, and no flipflops).'},
      {label: 'GOOD FOR', value: 'MUSIC', desc: 'A great option to dance, socialize and listen to music.'},
    ]
  },
  "vesica-tulum": {
    name: 'Vesica',
    price: 3,
    directions: {
      address:
        'PARCELA No. 198Z-2P-1 EJIDO, 77760 Tulum, Q.R., Mexico',
      googleMap:
        'pb=!1m18!1m12!1m3!1d3744.4923217140167!2d-87.50500862659484!3d20.196852915834558!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4fd7140eb66e15%3A0x83e387fefe923fef!2sVesica%20Tulum%20%7C%20Cenote%20Club!5e0!3m2!1sen!2ses!4v1736526947795!5m2!1sen!2ses',
    },
    tags: [
      {label: 'DAY', value: '9AM-6PM', desc: 'Opened during the Day.'},
      {
        label: 'NIGHT',
        value: '--',
        desc:
          'Opens during the night for special parties and events. ',
      },
      {
        label: 'SHOW',
        value: 'Nature-related Activities',
        desc: 'Djs and Nature-related activities',
      },
      {
        label: 'MUSIC',
        value: 'MIXTURE OF HOUSE MUSIC',
        desc:
          'Top-Notch DJs for special events.',
      },
      {
        label: 'DRESS',
        value: 'PARTY-CASUAL',
        desc:
          'Casual, Functional and Fun',
      },
      {
        label: 'GOOD FOR',
        value: 'GROUP ACTIVITY',
        desc:
          'Perfect plan for a day out, dine and experience the magic of a cenote in Tulum.',
      },
    ],
  },
  "bak-tulum": {
    name: 'Bak Steakhouse',
    price: 3,
    directions: {
      address:
        'Carr. Tulum-Boca Paila Km. 8, Tulum Beach, Zona Hotelera, 77780 Tulum, Q.R., Mexico',
      googleMap:
        'pb=!1m18!1m12!1m3!1d3745.538579262748!2d-87.46022112659632!3d20.153288917274846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4fd1bad7999a41%3A0x33250a1947238f5a!2sBAK%E2%80%99%20%7C%20Fire%20Steakhouse!5e0!3m2!1sen!2ses!4v1736526054329!5m2!1sen!2ses',
    },
    tags: [
      { label: 'DAY', value: '--', desc: 'Closed before 5pm.' },
      {
        label: 'NIGHT',
        value: '5PM-1AM',
        desc:
          'Open everyday in the afternoon til late. ',
      },
      {
        label: 'SHOW',
        value: 'THEMED FIRE SHOW',
        desc: 'Professional fire performers.',
      },
      {
        label: 'MUSIC',
        value: 'MIXTURE OF HOUSE AND LOUNGE MUSIC',
        desc:
          'A taste of many genres and styles.',
      },
      {
        label: 'DRESS',
        value: 'PARTY-CASUAL',
        desc:
          'Casual, Functional and Fun',
      },
      {
        label: 'GOOD FOR',
        value: 'GROUP PARTY',
        desc:
          'Perfect plan for a night out, catch a show and dine in a signature venue in Tulum.',
      },
    ],
  },

});
