<template>
  <div class="content">
    <div class="row" style="background: linear-gradient(#14191c, #2e3935);
">
      <div class="text-center col-xl-8 col-sm-12">


        <h1
          class="tulum-party-title text-center"
          style="
            margin-top: 30px;
            text-transform: uppercase;
            letter-spacing: 0.2rem;
          "
        >
          Tulum <br> Concierge Services
        </h1>

        <div class="card text-center" style="display: inline-block;padding: 20px; ">

          <img
            v-lazy="pinaColada+'?fit=clamp&h=100&w=80'" width="80" height="100" alt="sit back and relax">
          <br>

          <br>
          <br>
          <p>Kick Back, Unwind, Let Us Handle All Your Plans</p>

          <img
            v-lazy="logo + '?h=12'"
            height="12px"
            alt="tulum party logo"
          />
          <hr>

        <p>
          Bachelor blowouts? Festival frenzy?  Jungle parties escapades?  We got it all!  Beachside chills, delicious eats, unforgettable experiences guaranteed.  Ditch the stress, we handle the details.
          <br>

          <small>NO EXTRA FEE</small>
        </p>
        </div>
        <div class="card" style="padding: 20px;background-color:#515151">
          <p>Daylight or Disco Ball, Let's Celebrate All Y'all! Whether you're craving sunshine and beach vibes or dazzling lights and dance floors, we've got the perfect party for you.  Join us for unforgettable fun, day or night!</p>
        </div>
        <card
          class="card text-center col-md-12 col-md-10 offset-md-1"
          style="z-index: 1; margin: 2px;"
        >
          <div class="card"
                 style="padding: 20px"
                 :style="{ background: `linear-gradient(${this.secondaryColor}, black)` }"

            >
              <br/>
            <p>Trust our <strong> experience</strong> and make <strong>your tulum experience unforgettable</strong></p>


            <br>


            <RsvpViaDialog
              :club-config="this.rsvpConfig"
              mailAddress="gaby@tulum.party"
              message="CONCIERGE_INFO:%20%0a-------%0a"
              text="CONTACT US"
            ></RsvpViaDialog>
              <br>
              <br/>
              <small
              >We operate from 9AM - 11PM / Answer within 15 mins</small
              >
              <br>
          </div>

          <div class="card" style="background-color: #5d5c5c;padding: 20px">
            <img v-lazy="serviceIcon+'?w=80&h=80'" height="80" width="80" alt="contact us" class="centered">

            <h4 ><strong>Our Services</strong></h4>

            <ul>
              <li><strong>Seamless </strong>: Secure your spot at the hottest venues with our hassle-free reservation service.</li>
              <li><strong> Recommendations:</strong>** Don't waste time searching - we'll recommend the perfect clubs and events based on your preferences.</li>
              <li><strong> All the Way Through:</strong>**  From initial planning to the event itself, we're here to answer questions and ensure a smooth experience.</li>
              <li><strong> Miss a Beat:</strong>** We provide consistent follow-up, ensuring you have all the information and details you need.</li>
              <li><strong> Occasion Planning:</strong>** We handle the details for any celebration, making your bachelor/bachelorette party, birthday bash, or any other event unforgettable.</li>
              <li><strong> Your Celebration:</strong></li>
              <ul>
                <li>Bachelorette & Bachelor Party Planning</li>
                <li>Tulum Party Planning & Coordination</li>
              </ul>
              <li><strong> Unforgettable Nights:</strong></li>
              <ul>
                <li>Tulum Nightlife Concierge & VIP Access</li>
                <li>Tulum Nightclub & Beach Club Reservations</li>
                <li>Tulum Festival Tickets & VIP Experiences</li>
              </ul>
              <li><strong> Seamless Experience:</strong></li>
            </ul>


          </div>


          <card class="card text-left" style="margin-top: 1px">
            <div
              v-html="club.content"
              class="md-small-hide"
              style="
                color: #fff !important;
                margin-bottom: 1px;
                line-height: 1.4rem;
              "
            ></div>
          </card>


<!--          <div id="carrousel" style="margin-top: 30px">-->
<!--            <h3 class="text-left section-header">-->
<!--              <img-->
<!--                v-lazy="this.logo + '?w=40&h=40&fit=clamp'"-->
<!--                height="40px"-->
<!--                width="40px"-->
<!--                style="border-radius: 300px;margin-right: 10px;"-->
<!--              />-->
<!--              GALLERY-->
<!--            </h3>-->
<!--            <Carrousel folder="tulum-wedding-planning" :color="clubColor"></Carrousel>-->
<!--          </div>-->


          <br/>
        </card>
        <br/>

      </div>

      <div class="card col-4 col-xl-4 d-none d-xl-inline">
        <br>
        <h4 class="section-header" style="font-weight: bold">
          <i class="tim-icons icon-chat-33"></i> GET IN TOUCH
        </h4>
        <div class="row">
          <div class="col-sm-12 text-center">
            <p>
              <strong>Lean back, close your eyes</strong>, and get ready for some well-deserved party time!
            </p>
            <hr>
          </div>
          <div class="col-sm-12 text-center">
            <RsvpViaDialog
              :club-config="this.rsvpConfig"
              mailAddress="gaby@tulum.party"
              message="CONCIERGE_INFO:%20%0a-------%0a"
              text="INFO"
            ></RsvpViaDialog>
          </div>
        </div>
        <br>

        <section :style="{ background: `linear-gradient(${this.clubColor}, black )` }">

          <h4 class="section-header">
            <img v-lazy="serviceIcon" height="30px" width="30px" alt="our services">
            SERVICES
          </h4>
          <hr style="height: 2px" :style="{ backgroundColor: clubColor }"/>
            <ul>
              <li>Unbiased Recommendations</li>
              <li> Seamless Reservations</li>
              <li> Support All the Way Through</li>
              <li> Never Miss a Beat</li>
              <li>Bachelorette & Bachelor Party Planning</li>
              <li>Tulum Party Planning & Coordination</li>
              <li>Tulum Nightlife Concierge & VIP Access</li>
              <li>Tulum Nightclub & Beach Club Reservations</li>
              <li>Tulum Festival Tickets & VIP Experiences</li>
            </ul>
        </section>
        <section :style="{ background: `linear-gradient(${this.clubColor}, black )` }">

          <h4 class="section-header">
            <i class="tim-icons icon-map-big"></i>
             BENEFITS
          </h4>
          <hr style="height: 2px" :style="{ backgroundColor: clubColor }"/>

          <ul>
            <li><strong> Skip the Planning, Focus on Fun:</strong>Leave the logistics to us, you just enjoy the party!</li>
            <li><strong> VIP Treatment: </strong> Get exclusive access, reservations, and bottle service at the hottest spots.</li>
            <li><strong> Expert Local Knowledge: </strong> We know Tulum's party scene inside and out, ensuring an unforgettable experience.</li>
            <li><strong> Stress-Free Celebration: </strong> Relax and have fun, we'll handle all the details and arrangements.</li>
            <li><strong> Seamless Party Flow: </strong> Transportation, equipment rentals, and anything else you need for a smooth and enjoyable party.</li>
            <li><strong> Personalized Touch: </strong> Let us know your preferences and we'll create a party that's perfect for you.</li>
          </ul>
          <ul>

            <li><strong> Follow Up:</strong>No more chasing leads or unanswered messages. We ensure you get the information you need.</li>
            <li><strong> Single Contact for All Clubs & Events:</strong>Simplify your planning! One contact handles everything, saving you time and effort.</li>
            <li><strong> Skip the Bots & Get Fast Help from a Real Person:</strong> Talk to a real person who can answer your questions and guide you efficiently.</li>
            <li><strong> No Extra Fee:</strong>Enjoy all the benefits without hidden costs. We connect you with the best nightlife options at no additional charge.</li>
          </ul>
        </section>

      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import Reservations from '../../components/Reservations/Reservations';

const RsvpViaDialog = () =>
  import(
    /* webpackChunkName: "RsvpDialogs" */ '../../components/Reservations/RsvpViaDialog'
    );

import Carrousel from '@/components/Carrousel';

export default {
  name: 'ConciergeServices',
  props: ['slug'],
  metaInfo() {

    return {
      title: `Tulum | Concierge Services. Party Experts Concierge for Clubs, Festivals & More`,
      meta: [
        {
          name: 'description',
          content: `Bachelor blowouts? Festival frenzy? Jungle  escapades? We do it all! Beachside chills, delicious eats, unforgettable experiences guaranteed. Ditch the stress, we handle the details.`,
        },
        {
          name: 'keywords',
          content: `wedding planner, wedding planning tulum mexico, tulum destination wedding planner, riviera maya wedding planner, mexico wedding planner, tulum beach wedding, tulum wedding packages, tulum wedding ceremony, tulum wedding reception, luxury tulum wedding, bohemian tulum wedding, eco-friendly tulum wedding, tulum wedding photographer, tulum wedding videographer, tulum wedding vendors, tulum wedding planning on a budget, all-inclusive tulum wedding packages, intimate tulum wedding ceremony, unique tulum wedding venues, stress-free tulum destination wedding, tulum wedding with cenote ceremony, tulum wedding with Mayan influence, tulum wedding with live music, tulum wedding with fireworks, organizador de bodas tulum, planeación de bodas tulum mexico, boda destino tulum riviera maya, boda en la playa tulum, paquetes de boda tulum`
        },
        {
          property: 'og:title',
          content: ` Tulum Concierge Services`,
        },
        {
          property: 'og:url',
          content: `https://tulum.party/tulum-concierge-services`,
        },
        {
          property: 'og:description',
          content: ` Tulum Concierge Services`,
        },
        {property: 'og:type', content: 'article'},
        {
          property: 'og:image',
          content: this.main_image,
        },
      ],
    };
  },
  data: () => {
    return {
      showAll: false,
      logo: 'https://imgix.cosmicjs.com/3acdf350-9b87-11ee-b34c-99d1d89e42eb-tp-logo-40pt.png',
      pinaColada: 'https://imgix.cosmicjs.com/16bea710-3e94-11ef-a504-63e081e4680f-small-drink.png',
      showDetailsTab: true,
      showFaqsTab: false,
      showLocationTab: false,
      showReviewTab: false,
      video: false,
      clubColor: '#2d2b2b',
      images: ['https://image.lexica.art/full_webp/9e6db2e5-56dd-493b-a80e-9e9f3555dedf',
        'https://imgix.cosmicjs.com/82856090-f18b-11ec-8fb8-5d396858ac9b-photo2022-06-2112-46-08.jpg?h=430',
        'https://imgix.cosmicjs.com/491a08e0-b45b-11ed-bce9-6ddb530a836d-Bonbonniere-Tulum.jpg?w=580&h=350&fit=clamp&auto=format'

      ],
      secondaryColor: '#1f1f1f',
    showPalms: false,
      clubStyle: '',
      clubConfig: {},
      rsvpConfig: {name: 'Wedding Planners', logo: 'https://imgix.cosmicjs.com/f8422ee0-2801-11ef-adb1-8b946b3a80e4-service-icon.png',},
      clubTagsConfig: {},
      serviceIcon: 'https://imgix.cosmicjs.com/f8422ee0-2801-11ef-adb1-8b946b3a80e4-service-icon.png',
      main_image:
        'https://imgix.cosmicjs.com/f67523e0-c705-11ee-9ce5-59949019255e-djs.jpg',

    };
  },
  methods: {},
  components: {
    Carrousel,
    RsvpViaDialog,
    Reservations,
  },
  computed: mapState({
    incomingClubParties: (state) => {
      return state.incomingClubParties[state.venue.slug] || [];
    },
    club: (state) => state.venue,
    clubs: (state) => state.venues,
  }),
  created() {

  },
};
</script>
